export const toMMSS = (secs) => {
  const secNum = parseInt(secs, 10);
  //const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60);
  const seconds = secNum % 60;
  return [minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    //.filter((v, i) => v !== "00" || i > 0)
    .join(":");
};
